import React from 'react';
import { AnimatedDualMedia } from 'src/components/AnimatedDualMedia';
import { Box } from 'src/components/Box';
import { CheckListItem } from 'src/components/CheckListItem';
import { MediaSection } from 'src/components/MediaSection';
import { ThemeProvider } from 'src/components/ThemeProvider';

// used on Streaming method, Xenforo software pages
export const StreamingIntegrationSection = ({
    title,
    flipped = true,
    ...props
}) => {
    return (
        <ThemeProvider defaultMode="dark">
            <MediaSection
                title={title}
                flipped={flipped}
                bg="primaryBackground"
                customMedia={
                    <AnimatedDualMedia
                        video="/videos/methods/streaming/phone-app.mp4"
                        videoId="phone-app"
                        videoPoster="/videos/methods/streaming/phone-app-poster.jpg"
                        video2="/videos/methods/streaming/phone-stream.mp4"
                        video2Id="phone-stream"
                        video2Poster="/videos/methods/streaming/phone-stream-poster.jpg"
                    />
                }
                {...props}
            >
                <Box pt="spacing" as="ul">
                    {[
                        {
                            title: 'Reach your audience where they are ',
                            content:
                                'Stay in touch with native push notifications on iOS and Android.',
                        },
                        {
                            title: 'Get listed in app stores',
                            content:
                                'Gain exposure to a wider audience and a younger demographic.',
                        },
                        {
                            title: 'Expand your revenue opportunities',
                            content:
                                'An avenue for placing mobile ads, app sales, and subscriptions.',
                        },
                    ].map(highlight => (
                        <CheckListItem
                            key={highlight.title}
                            title={highlight.title}
                            as="li"
                        >
                            {highlight.content}
                        </CheckListItem>
                    ))}
                </Box>
            </MediaSection>
        </ThemeProvider>
    );
};
