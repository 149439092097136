import Img from 'gatsby-image';
import React from 'react';
import { Box } from 'src/components/Box';
import { Heading } from 'src/components/Heading';
import {
    FeatureGrid,
    FeatureItem
} from 'src/components/Landing/LandingFeatureGrid';
import { Section } from 'src/components/Section';
import { AvatarBackground } from './AvatarBackground';

const items = [
    {
        heading: 'live stream and chat',
        content:
            'Have real-time discussion with members and also member-to-member engagement.',
    },
    {
        heading: 'subscriptions and donations',
        content:
            'Monetize your way by having subscription tiers, donations, and ticket sales.',
    },
    {
        heading: 'your brand, not ours',
        content:
            'Your brand and colors across the entire experience including logo placement.',
    },
];

export const VideoHeroSection = ({ image, alt, ...props }) => {
    return (
        <>
            <Section
                vertical="lg"
                wrap
                maxWidth={992}
                pos="relative"
                pb={[0, 0, 0]}
                {...props}
            >
                <Heading kind="h3" as="h2" textAlign="center" center mb={0}>
                    A modern live streaming solution for creators, artists,
                    community managers, educators, and more.
                </Heading>
            </Section>

            <Section
                wrap
                maxWidth={992}
                pos="relative"
                overflow="hidden"
                pb={[0, 0, 0]}
            >
                <Box zIndex={1} pos="relative">
                    <Box w="100%">
                        <Box>
                            <Img
                                alt={alt}
                                style={{ width: '100%', height: '100%' }}
                                fluid={image}
                            />
                        </Box>
                    </Box>
                </Box>

                <AvatarBackground />
            </Section>

            <Section vertical="lg" wrap maxWidth={992} pos="relative">
                <FeatureGrid>
                    {items.map((method, index) => (
                        <FeatureItem
                            key={index}
                            heading={method.heading}
                            content={method.content}
                        />
                    ))}
                </FeatureGrid>
            </Section>
        </>
    );
};
