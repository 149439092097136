/** @jsx jsx */
import { Flex } from '@audentio/stuff/src/Flex';
import { css, jsx, keyframes } from '@emotion/core';
import { useMemo } from 'react';
import { BounceAvatar } from './BounceAvatar';

export const durationOnScreen = 30000; // in ms

// factor should be the total vw distance to travel / 100;
export const factor = 2;

const initialSlide = keyframes`
    0% {
        transform: translateX(0vw);  
    }
    100% {
        transform: translateX(-100vw);  
    }
`;

const slide = keyframes`
    0% {
        transform: translateX(100vw);  
    }
    100% {
        transform: translateX(-100vw);  
    }
`;

export const EventGroup = ({
    type = 'infinite',
    id,
    eventList,
    isDelayed = false,
}) => {
    const slideProps = useMemo(() => {
        if (type === 'initial') {
            return {
                css: css`
                    animation: ${initialSlide} ${durationOnScreen}ms linear;
                    animation-fill-mode: forwards;
                    animation-play-state: running;
                    animation-iteration-count: 1;
                `,
            };
        }

        if (isDelayed) {
            return {
                css: css`
                    animation: ${slide} ${durationOnScreen * factor}ms linear;
                    animation-fill-mode: forwards;
                    animation-play-state: running;
                    animation-iteration-count: infinite;
                    animation-delay: ${durationOnScreen}ms;
                `,
            };
        }

        return {
            css: css`
                animation: ${slide} ${durationOnScreen * factor}ms linear;
                animation-fill-mode: forwards;
                animation-play-state: running;
                animation-iteration-count: infinite;
            `,
        };
    }, []);

    return (
        <Flex
            {...(isDelayed ? { transform: 'translateX(-100vw)' } : {})}
            {...slideProps}
            position="absolute"
            left={0}
            top={0}
            bottom={0}
            margin="auto"
            align="center"
            w="100%"
        >
            {eventList?.items?.map(e => (
                <BounceAvatar key={id + '_' + e.src} prof={e} mr="spacing" />
            ))}
        </Flex>
    );
};
