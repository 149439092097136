import { Box } from '@audentio/stuff/src/Box';
import React, { useMemo } from 'react';
import { uuidv4 } from 'src/helpers/uuidv4';
import { EventGroup } from './EventGroup';

export const AvatarRowList = ({ profiles: avatars }) => {
    const initialList = useMemo(
        () => ({
            id: uuidv4(),
            items: avatars,
        }),
        []
    );

    const delayedList = useMemo(
        () => ({
            id: uuidv4(),
            items: avatars,
        }),
        []
    );

    return (
        <Box w="100%" h="50%" pos="relative">
            {/* Group 1 avatars - to start with avatars on screen - removed after 1 cycle */}
            <EventGroup
                type="initial"
                key={initialList.id + 'init'}
                id={initialList.id + 'init'}
                eventList={initialList}
            />
            {/* Group 2 avatars - starts animating right away, offscreen, infinite */}
            <EventGroup
                key={delayedList.id}
                id={delayedList.id}
                eventList={delayedList}
            />
            {/* Group 1 avatars - delayed by 1 viewport animation time, offscreen,
            infinite */}
            <EventGroup
                key={initialList.id}
                id={initialList.id}
                eventList={initialList}
                isDelayed
            />
        </Box>
    );
};
