import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import { Box } from 'src/components/Box';
import { useTheme } from 'src/components/ThemeProvider';
import { useWindowResize } from 'src/helpers/useWindowResize';
import { AvatarRowList } from './AvatarRowList';

export const AvatarBackground = () => {
    const data = useStaticQuery(query);
    const profiles = [
        data.profile1.childImageSharp.fluid,
        data.profile2.childImageSharp.fluid,
        data.profile3.childImageSharp.fluid,
        data.profile9.childImageSharp.fluid,
        data.profile4.childImageSharp.fluid,
        data.profile5.childImageSharp.fluid,
        data.profile6.childImageSharp.fluid,
        data.profile7.childImageSharp.fluid,
        data.profile8.childImageSharp.fluid,
        data.profile9.childImageSharp.fluid,
    ];

    const { breakpoint } = useTheme();
    const { windowWidth } = useWindowResize();
    const isAnimated = breakpoint.stripped.lg < windowWidth;

    if (!isAnimated) {
        return null;
    }

    return (
        <Box
            w="100%"
            h="100%"
            pos="absolute"
            top={0}
            left={0}
            overflow="hidden"
            opacity={0.5}
        >
            <AvatarRowList profiles={profiles && profiles.slice(0, 5)} />
            <AvatarRowList
                profiles={profiles && profiles.slice(5, profiles.length)}
            />
        </Box>
    );
};

const query = graphql`
    query profilesQuery {
        profile1: file(relativePath: { eq: "images/streaming/profile-1.png" }) {
            childImageSharp {
                fluid {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        profile2: file(relativePath: { eq: "images/streaming/profile-2.png" }) {
            childImageSharp {
                fluid {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        profile3: file(relativePath: { eq: "images/streaming/profile-3.png" }) {
            childImageSharp {
                fluid {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        profile4: file(relativePath: { eq: "images/streaming/profile-4.png" }) {
            childImageSharp {
                fluid {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        profile5: file(relativePath: { eq: "images/streaming/profile-5.png" }) {
            childImageSharp {
                fluid {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        profile6: file(relativePath: { eq: "images/streaming/profile-6.png" }) {
            childImageSharp {
                fluid {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        profile7: file(relativePath: { eq: "images/streaming/profile-7.png" }) {
            childImageSharp {
                fluid {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        profile8: file(relativePath: { eq: "images/streaming/profile-8.png" }) {
            childImageSharp {
                fluid {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        profile9: file(relativePath: { eq: "images/streaming/profile-9.png" }) {
            childImageSharp {
                fluid {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
    }
`;
