import { Grid } from '@audentio/stuff/src';
import { Layout } from 'components/Layout';
import { graphql } from 'gatsby';
import React from 'react';
import { LandingFeatureGrid } from 'src/components/Landing/LandingFeatureGrid';
import { MediaSection } from 'src/components/MediaSection';
import { MethodsCTA } from 'src/components/Methods/MethodsCTA';
import {
    ClientHighlight,
    StatBox,
} from 'src/components/Methods/metrics/ClientHighlight';
import { StreamingIntegrationSection } from 'src/components/Methods/streaming/StreamingIntegrationSection';
import { VideoHeroSection } from 'src/components/Methods/streaming/VideoHeroSection';
import { MethodsLandingHero } from 'src/components/MethodsLandingHero';
import { ScrollingList } from 'src/components/ScrollingList';
import { ThemeProvider } from 'src/components/ThemeProvider';

const subnav = [
    {
        id: 'overview',
        label: 'Overview',
    },
    {
        id: 'events',
        label: 'Events',
    },
    {
        id: 'features',
        label: 'Features',
    },
    {
        id: 'community-app',
        label: 'Community App',
    },
];

export default function streaming({ data }) {
    const flairColor = '#00C8D5';
    return (
        <Layout
            pageTitleFull="Live stream on your own social platform with Audentio"
            siteDescription="Live stream and chat with your own online community via web or your own app while monetizing your way."
            contactForm
            methodSubNavIcon="/methods/build.svg"
            methodSubNavColor={flairColor}
            subnavTitle="Live Events"
            subnav={subnav}
            wrapChildren
        >
            <MethodsLandingHero
                title="Engage directly with your audience by live streaming"
                image={data.heroStatic.childImageSharp.fluid}
                // TODO: update with streaming video once available
                // video="/videos/methods/streaming/person-talking.mp4"
                // videoPoster="/videos/methods/streaming/hero-poster.png"
                // videoId="streaming-hero-person-talking"
                subtitle="Bring live streaming together with your discussions, memberships, and content. Integrate a thriving community wherever your audience is, all under your own brand."
            />

            <VideoHeroSection
                id={subnav[0].id}
                image={data.streaminterface.childImageSharp.fluid}
                alt="Live streaming event in progress with host talking and audience engaging in the chat box."
            />

            <ThemeProvider defaultMode="dark">
                <MediaSection
                    id={subnav[1].id}
                    title="Gather your audience and start hosting your own."
                    pb={[0, 0, 0]}
                    flipped
                    middleAlign
                    bg="primaryBackground"
                    contentProps={{
                        maxWidth: {
                            _: '100%',
                            lg: 375,
                            xl: 450,
                        },
                    }}
                    customMedia={
                        <ScrollingList
                            items={[
                                { title: 'Community roundtable' },
                                { title: 'Question and answer' },
                                { title: 'Networking event' },
                                { title: 'Product launch' },
                                { title: 'Gaming event' },
                                { title: 'Contest celebration' },
                                { title: 'Jam session' },
                                { title: 'Charity event' },
                                { title: 'Conference' },
                                { title: "Meet n' Greet" },
                                { title: 'Award show' },
                                { title: 'Board meeting' },
                                { title: 'Reunion' },
                                { title: 'Webinar' },
                                { title: 'Panel discussion' },
                                { title: 'Lessons and tutorials' },
                            ]}
                        />
                    }
                />
            </ThemeProvider>

            <MethodsCTA
                title="Save your time. Get higher returns. Have the community you deserve."
                flairColor={flairColor}
            />

            <MediaSection
                id={subnav[2].id}
                image={data.monetize.childImageSharp.fluid}
                title="Monetize your way and decide your percentage cut"
                bg="primaryBackground"
                alt="Donation goal to a live streamer with comparison of percentage cuts with most streaming platforms."
            >
                Have full control on your return with support for subscriptions,
                donations, paid chat messages, donation goals and alerts, and if
                you have an existing community, a single integrated
                subscription.
            </MediaSection>

            <MediaSection
                id="events"
                // videoPoster="/videos/vbmigrationposter.jpg"
                video="/videos/methods/streaming/person-talking.mp4"
                videoId="streaming-person-talking"
                title="Create exclusive events with optional ad-free experiences"
                bg="primaryBackground"
                flipped
            >
                At times advertisements feel out of place for certain events and
                YouTube and Twitch offer no other route. We leave it to you to
                decide what is best. We also support recording to VOD and having
                a co-host.
            </MediaSection>

            <MediaSection
                id="chatbox"
                video="/videos/methods/streaming/chatbox.mp4"
                videoId="streaming-chatbox"
                title="Built for engaging discussions and connections"
                bg="primaryBackground"
            >
                Chat and chat moderation, message and emoji, members-only chat
                mode, slow-mode chat, polls, filter chat, chat rules, and no
                limits on emotes.
            </MediaSection>

            <ClientHighlight
                title="NORA 2021 Conference"
                image={data.nora.childImageSharp.fluid}
                bg="primaryBackground"
                description="The NORA annual conference is a multi-day event that brings together professionals and key leaders in the optometry and other medical fields. With Audentio Live, we were able to host their event and tailor the platform to their specific needs."
                pt={[0, 0, 0]}
                alt="Screengrabs from the Nora 2021 Conference"
                gridSectionProps={{ borderTop: '0px' }}
                link="https://www.audent.io/case-study/nora/full"
                linkText="View Case Study"
            >
                <Grid
                    columns={{
                        _: 2,
                        lg: 1,
                    }}
                    spacing={{
                        _: 'spacing-lg',
                        lg: 'spacing-xl',
                    }}
                    w={{
                        _: '100%',
                        lg: 250,
                    }}
                >
                    <StatBox
                        title="2,152"
                        subtitle="Number of"
                        kind="Chat messages"
                    />
                    <StatBox
                        title="291"
                        subtitle="Number of"
                        kind="Concurrent viewers"
                    />
                </Grid>
            </ClientHighlight>

            <LandingFeatureGrid
                bg="secondaryBackground"
                title="Built with community in mind, our live streaming solution stands apart"
                items={[
                    {
                        heading: 'XenForo integration/OAuth',
                        content:
                            'You’ll have a single integrated subscription model and one payment to manage.',
                    },
                    {
                        heading: 'unified account for members',
                        content:
                            'Achievements and experience points will be shared between the community and streaming. ',
                    },
                    {
                        heading: 'you own your audience',
                        content:
                            'You can send your own push notifications and be in direct contact with your audience.',
                    },
                    {
                        heading: 'control over brand and features',
                        content:
                            'No longer have third-party logos like Twitch and YouTube present on your platform.',
                    },
                    {
                        heading: 'no limits on emotes',
                        content:
                            'No limits on the number of emotes you get based on your audience size.',
                    },
                    {
                        heading: 'larger share of subscriptions',
                        content:
                            'Compared to YouTube and Twitch that take 50%, you can decide how much return you have.',
                    },
                ]}
                mb="0"
            />

            <StreamingIntegrationSection
                id={subnav[3].id}
                title="Live streaming integrated with our community app"
            />
        </Layout>
    );
}

export const query = graphql`
    query streamingQuery {
        streaminterface: file(
            relativePath: { eq: "images/xfsoftware/stream-interface.png" }
        ) {
            childImageSharp {
                fluid(maxWidth: 1200) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        heroStatic: file(
            relativePath: { eq: "images/streaming/hero-static.png" }
        ) {
            childImageSharp {
                fluid {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        monetize: file(
            relativePath: { eq: "images/streaming/streaming-monetize.png" }
        ) {
            childImageSharp {
                fluid {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        nora: file(relativePath: { eq: "images/streaming/nora-hero.png" }) {
            childImageSharp {
                fluid {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        streamingphone1: file(
            relativePath: { eq: "images/streaming/streaming-phone-1.png" }
        ) {
            childImageSharp {
                fluid {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        streamingphone2: file(
            relativePath: { eq: "images/streaming/streaming-phone-2.png" }
        ) {
            childImageSharp {
                fluid {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
    }
`;
